import PropTypes from "prop-types";
import { isAfter, isBefore } from "date-fns";
import { utcToZonedTime } from "date-fns-tz";

import { withALErrorBoundary } from "../../../helpers/ErrorBoundary/ALErrorBoundary";
import { useLocalizedSentenceDict } from "../../../hooks/useSentenceDict";

import ExcludedFromPromotionText from "../ExcludedFromPromotionText";
import EngravingShippingDetails from "./productShipping/EngravingShippingDetails";
import { ALPaymentMethod } from "../../ALComponents";
import { getShipByDate } from "../../../helpers/product";
import TAG from "../../../constants/Tag";
import { HandleType } from "../../../constants/HandleType";
import { Country } from "../../../constants/Country";
import { PREORDER_TIMEZONE } from "../../../constants/Common";
import { ComponentType } from "../../../constants/ComponentType";
import { InventoryPolicies } from "../../../constants/InventoryPolicies";

const isBetweenDates = (startDate, endDate) => {
  if (!startDate || !endDate) return false;

  const _startDate = utcToZonedTime(startDate, PREORDER_TIMEZONE);
  const _endDate = utcToZonedTime(`${endDate}T23:59:59Z`, PREORDER_TIMEZONE);
  const _now = new Date();
  return isAfter(_now, _startDate) && isBefore(_now, _endDate);
};

function ProductShipping({
  country,
  tags,
  isEngravable,
  productHandle,
  selectedVariant,
  preOrder,
  isNumberCharmProduct,
  componentType,
}) {
  const dict = useLocalizedSentenceDict();

  const isGiftCard = productHandle === HandleType.GIFT_CARD;
  const isInventoryPolicyDeny = selectedVariant?.inventoryPolicy === InventoryPolicies.DENY;
  const isInventoryPolicyContinue = selectedVariant?.inventoryPolicy === InventoryPolicies.CONTINUE;
  const isFinalSale = tags?.includes(TAG.FINAL_SALE);

  return (
    <div
      className={`pdp_shipping_options ${
        country !== Country.US ? "pdp_payment_options_inter" : ""
      }`}
    >
      {isNumberCharmProduct && (
        <p className="pdp_shipping_options--numberchains">Charms made to fit this chain only.</p>
      )}
      {country !== Country.US && componentType !== ComponentType.PDP_ADS && (
        <ALPaymentMethod locale={country} />
      )}

      {country === Country.US && !isGiftCard && isInventoryPolicyDeny && isEngravable && (
        <div className="pdp_shipping_options--delivery">
          <EngravingShippingDetails />
        </div>
      )}

      {isFinalSale && !isGiftCard && componentType !== ComponentType.PDP_ADS && (
        <p className="pdp_shipping_options--finalsale">{dict.get("Final Sale")}</p>
      )}

      <ExcludedFromPromotionText tags={tags} />

      {isBetweenDates(preOrder?.startDate, preOrder?.endDate) &&
        !isGiftCard &&
        isInventoryPolicyContinue && (
          <>
            <p className="pdp_shipping_options--excluded">
              This item is available for preorder, and will ship to you once back in stock.
            </p>
            <p className="pdp_shipping_options--preorder">
              Ships by: <b>{getShipByDate(preOrder?.shippingDate)}</b>
            </p>
          </>
        )}
    </div>
  );
}

ProductShipping.propTypes = {
  country: PropTypes.string,
  tags: PropTypes.arrayOf(PropTypes.string),
  isEngravable: PropTypes.bool,
  productHandle: PropTypes.string,
  selectedVariant: PropTypes.object.isRequired,
  preOrder: PropTypes.object,
  isNumberCharmProduct: PropTypes.bool,
  componentType: PropTypes.string,
};

export default withALErrorBoundary({
  name: "ProductShipping",
  priority: "P1",
})(ProductShipping);
