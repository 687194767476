import PropTypes from "prop-types";

import { withALErrorBoundary } from "../../helpers/ErrorBoundary/ALErrorBoundary";
import { useLocalizedSentenceDict } from "../../hooks/useSentenceDict";
import { useCurrentCountryCode } from "../../hooks/usePrices";
import { useExclusionTags } from "../../hooks/useExclusionTags";
import { Country } from "../../constants/Country";
/**
 *** US ***
 * Excluded from promotion text shows:
 * - on banned products
 * - on gift card
 *
 *** INTL ***
 * Excluded from promotion text shows:
 * - on banned products only (except gift card)
 */

function ExcludedFromPromotionText({ tags }) {
  const exclusionTags = useExclusionTags();
  const dict = useLocalizedSentenceDict();
  const countryCode = useCurrentCountryCode();

  const isExcludedProduct = (tags || []).some((r) => exclusionTags.includes(r));

  if (
    tags.includes("Components:10K Solid Gold") ||
    tags.includes("Components:10K Solid White Gold")
  ) {
    return (
      <p className="pdp_shipping_options--excluded">AL Luxe Members Save More — 5% off this item</p>
    );
  }

  if (!isExcludedProduct) {
    return null;
  }

  return (
    <p className="pdp_shipping_options--excluded">
      {countryCode === Country.US
        ? dict.get("Excluded from AL Luxe discount and Free Gift offer")
        : dict.get("Excluded from sitewide promotions")}
    </p>
  );
}

ExcludedFromPromotionText.propTypes = {
  tags: PropTypes.arrayOf(PropTypes.string),
};

export default withALErrorBoundary({
  name: "ExcludedFromPromotionText",
  priority: "P3",
})(ExcludedFromPromotionText);
